import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  List,
  ListItem,
  Box,
  makeStyles,
  Card,
  ListItemText,
  ListSubheader,
  CardContent
} from '@material-ui/core'
import { SyncAlt, Edit, Person, Settings, AccessTime } from '@material-ui/icons'
import { AppContext } from 'contexts/AppContext'
import { Link } from 'react-router-dom'
import AuthorizedComponent from 'modules/authentication/components/AuthorizedComponent'
import dayjs from 'dayjs'

const HomePage: React.SFC = () => {
  const classes = useStyles()
  const { state } = useContext(AppContext)

  return (
    <div>
      <Card className={classes.content}>
        <CardContent>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Welcome {state.session.user!.name}
          </Typography>

          <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
            <AccessTime style={{ fontSize: 16 }} /> Last login:&nbsp;
            {dayjs(state.session.user!.lastLoggedTime).format('L LT')}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.content}>
        <Grid container justify="flex-start">
          <AuthorizedComponent resource="transactions" scopes={['read']}>
            <Grid item className={classes.item} md={6} lg={3}>
              <Box m={2}>
                {/* <img src={`${process.env.PUBLIC_URL}/images/icon-transactions.png`} /> */}
                <SyncAlt color="primary" fontSize="large" />
              </Box>

              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Transactions
                    </Typography>
                  </ListSubheader>
                }
              >
                <AuthorizedComponent resource="transactions" scopes={['read']}>
                  <Link to="/transactions">
                    <ListItem button>
                      <ListItemText primary="Transactions" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>

                <AuthorizedComponent resource="settlements" scopes={['read']}>
                  <Link to="/settlements">
                    <ListItem button>
                      <ListItemText primary="Settlements" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>

                <AuthorizedComponent resource="void" scopes={['read']}>
                  <Link to="/void">
                    <ListItem button>
                      <ListItemText primary="Void" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>

                <AuthorizedComponent resource="refunds" scopes={['read']}>
                  <Link to="/refunds">
                    <ListItem button>
                      <ListItemText primary="Refunds" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
              </List>
            </Grid>
          </AuthorizedComponent>

          <Grid item className={classes.item} md={6} lg={3}>
            <Box m={2}>
              <Edit color="primary" fontSize="large" />
            </Box>
            <List
              component="nav"
              subheader={
                <ListSubheader>
                  <Typography
                    component="h3"
                    gutterBottom
                    variant="h3"
                    style={{ fontWeight: 'bold' }}
                  >
                    Manage
                  </Typography>
                </ListSubheader>
              }
            >
              <AuthorizedComponent resource="partners" scopes={['read']}>
                <Link to="/partners">
                  <ListItem button>
                    <ListItemText primary="Partners list" />
                  </ListItem>
                </Link>
                <AuthorizedComponent resource="users" scopes={['read']}>
                  <Link to="/users/partner">
                    <ListItem button>
                      <ListItemText primary="Partners users" />
                    </ListItem>
                  </Link>
                </AuthorizedComponent>
              </AuthorizedComponent>

              <AuthorizedComponent resource="merchants" scopes={['read']}>
                <Link to="/merchants">
                  <ListItem button>
                    <ListItemText primary="Merchant list" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>
              <AuthorizedComponent resource="users" scopes={['read']}>
                <Link to="/users/merchant">
                  <ListItem button>
                    <ListItemText primary="Merchant users" />
                  </ListItem>
                </Link>
              </AuthorizedComponent>
            </List>
          </Grid>

          <AuthorizedComponent roles={['admin']}>
            <Grid item className={classes.item} md={6} lg={3}>
              <Box m={2}>
                <Person color="primary" fontSize="large" />
              </Box>
              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Users
                    </Typography>
                  </ListSubheader>
                }
              >
                <Link to="/users/admin">
                  <ListItem button>
                    <ListItemText primary="Bank admin users" />
                  </ListItem>
                </Link>
                <Link to="/roles">
                  <ListItem button>
                    <ListItemText primary="Roles management" />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </AuthorizedComponent>

          <AuthorizedComponent roles={['admin']}>
            <Grid item className={classes.item} md={6} lg={3}>
              <Box m={2}>
                <Settings color="primary" fontSize="large" />
              </Box>
              <List
                component="nav"
                subheader={
                  <ListSubheader>
                    <Typography
                      component="h3"
                      gutterBottom
                      variant="h3"
                      style={{ fontWeight: 'bold' }}
                    >
                      Options
                    </Typography>
                  </ListSubheader>
                }
              >
                <Link to="/settings">
                  <ListItem button>
                    <ListItemText primary="System &amp; Velocity settings" />
                  </ListItem>
                </Link>

                <Link to="/settings/velocity">
                  <ListItem button>
                    <ListItemText primary="Velocity settings" />
                  </ListItem>
                </Link>

                <Link to="/settings/binlist">
                  <ListItem button>
                    <ListItemText primary="BIN settings" />
                  </ListItem>
                </Link>

                <Link to="/audits">
                  <ListItem button>
                    <ListItemText primary="Audit logs" />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </AuthorizedComponent>
        </Grid>
      </Card>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  content: { margin: theme.spacing(4), padding: 0 },
  item: {
    padding: theme.spacing(3),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        // borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}))

export default HomePage
