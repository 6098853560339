import axios from 'axios'

const SettlementApi = axios.create({
  baseURL: process && process.env && process.env.REACT_APP_SETTLEMENT_URL,
  timeout: 60000
})

SettlementApi.defaults.baseURL =
  process && process.env && process.env.REACT_APP_SETTLEMENT_URL

export default SettlementApi
