import axios, { AxiosError, AxiosResponse } from 'axios'

const Api = axios.create({
  baseURL: process && process.env && process.env.REACT_APP_API_URL,
  timeout: 60000
})

class ApiErrorParser {
  constructor(public error: any) {}

  toString() {
    return this.formatError(this.error)
  }

  formatError(arg: any) {
    if (process.env.NODE_ENV === 'development') console.error(arg)

    if (
      arg instanceof Object &&
      (arg as AxiosError).response &&
      (arg as AxiosError).response
    ) {
      const a = arg as AxiosError

      // console.error('axios errors', a.response ? a.response.data : '')
      if (a.response && a.response.data && a.response.data.error) {
        if (Array.isArray(a.response.data.error.details)) {
          return a.response.data.error.details.map((m: any) => m.message)[0]
        }
        return a.response.data.error.message
      } else if (a.response && a.response.status && a.response.statusText) {
        return a.response.statusText
      }
      return a
    } else if (
      arg instanceof Object &&
      (arg as AxiosResponse).status &&
      (arg as AxiosResponse).statusText
    ) {
      return (arg as AxiosResponse).statusText
    } else if (arg instanceof Error) {
      return arg.message
    } else if (typeof arg === 'string') {
      return arg
    }
  }
}

export default ApiErrorParser
