import palette from '../palette'
import typography from '../typography'

export default {
  root: {
    ...typography.body1,
    fontSize: '12px',
    borderBottom: `1px solid ${palette.divider}`,
    padding: 10
  }
}
