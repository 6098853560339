import React, { Suspense } from 'react'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import PropTypes from 'prop-types'
import { LinearProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}))

const ErrorLayout: React.FC<RouteConfigComponentProps<{ routes: any }>> = ({ route }) => {
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>{renderRoutes(route?.routes)}</Suspense>
    </main>
  )
}

export default ErrorLayout
