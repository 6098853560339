/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import HomeIcon from '@material-ui/icons/HomeOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import ChildCareIcon from '@material-ui/icons/ChildCare'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import AssignmentIcon from '@material-ui/icons/Assignment'
import SchoolIcon from '@material-ui/icons/School'
import BusinessIcon from '@material-ui/icons/Business'
import BlurLinearIcon from '@material-ui/icons/BlurLinear'
import ReceiptIcon from '@material-ui/icons/Receipt'
import FindInPage from '@material-ui/icons/FindInPage'
import StoreIcon from '@material-ui/icons/Store'
import PartnerUserIcon from '@material-ui/icons/SupervisorAccount'
import AdminIcon from '@material-ui/icons/SupervisedUserCircle'
import RoleIcon from '@material-ui/icons/EnhancedEncryption'
import {
  Settings as SettingsIcon,
  MoneyOutlined,
  MoneyOffOutlined,
  MoneyOffRounded,
  MoneyTwoTone,
  Warning,
  Policy,
  HourglassEmpty
} from '@material-ui/icons'
import User from 'models/User'
import { useContext, Context } from 'react'
import { AppContext, AuthorizedParams } from 'contexts/AppContext'
import SettingsPage from 'modules/settings/SettingsPage'

const createNavigationConfig = (
  context: { isAuthorized: (params: AuthorizedParams) => boolean },
  user?: User
) => {
  const { isAuthorized } = context

  const navigationConfig = [
    {
      title: 'DASHBOARD',
      pages: [
        {
          title: 'Quick actions',
          href: '/home',
          icon: HomeIcon
        }
      ]
    }
  ]

  if (isAuthorized({ resource: 'transactions', scope: 'read' })) {
    const pages = [
      {
        title: 'Search',
        href: '/transactions',
        icon: FindInPage
      }

      // {
      //   title: 'Statistic',
      //   href: '/Statistic',
      //   icon: EqualizerIcon
      // }
    ]

    if (isAuthorized({ resource: 'risks', scope: 'read' })) {
      pages.push({
        title: 'Risks',
        href: '/risks',
        icon: Policy
      })
    }

    if (isAuthorized({ resource: 'settlements', scope: 'read' })) {
      pages.push({
        title: 'Settlement',
        href: '/settlements',
        icon: BlurLinearIcon
      })
    }

    navigationConfig.push({
      title: 'Transactions',
      pages
    })
  }

  const partnersPages = []

  if (isAuthorized({ resource: 'partners', scope: 'read' })) {
    partnersPages.push({
      title: 'Partners',
      href: '/partners',
      icon: BusinessIcon
    })
  }

  if (isAuthorized({ resource: 'users', scope: 'supervise' })) {
    partnersPages.push({
      title: 'Partner Users',
      href: '/users/partner',
      icon: PartnerUserIcon
    })
  }

  if (partnersPages.length > 0) {
    navigationConfig.push({
      title: 'Partners',
      pages: partnersPages
    })
  }

  const merchantsPages = []

  if (isAuthorized({ resource: 'merchants', scope: 'read' })) {
    merchantsPages.push({
      title: 'Merchants',
      href: '/merchants',
      icon: StoreIcon
    })
  }

  if (isAuthorized({ resource: 'users', scope: 'supervise' })) {
    merchantsPages.push({
      title: 'Merchant Users',
      href: '/users/merchant',
      icon: PeopleIcon
    })
  }

  if (merchantsPages.length > 0) {
    navigationConfig.push({
      title: 'Merchants',
      pages: merchantsPages
    })
  }

  let settingPages = []

  if (isAuthorized({ resource: 'currencies', scope: 'supervise' })) {
    settingPages.push({
      title: 'Currencies',
      href: '/currencies',
      icon: MoneyOffRounded
    })
  }
  if (isAuthorized({ resource: 'audits', scope: 'supervise' })) {
    settingPages.push({
      title: 'Audits',
      href: '/audits',
      icon: HourglassEmpty
    })
  }

  if (isAuthorized({ resource: 'system', scope: 'read' })) {
    settingPages = settingPages.concat([
      {
        title: 'Admin Users',
        href: '/users/admin',
        icon: AdminIcon
      }
    ])
  }

  if (isAuthorized({ resource: 'roles', scope: 'supervise' })) {
    settingPages.push({
      title: 'Manage Roles',
      href: '/roles',
      icon: RoleIcon
    })
  }

  if (isAuthorized({ resource: 'roles', scope: 'supervise' })) {
    settingPages.push({
      title: 'Providers',
      href: '/providers',
      icon: RoleIcon
    })
  }

  if (isAuthorized({ resource: 'system', scope: 'supervise' })) {
    settingPages.push({
      title: 'Settings',
      href: '/settings',
      icon: SettingsIcon
    })
  }

  if (settingPages.length > 0) {
    navigationConfig.push({
      title: 'Settings & Administrations',
      pages: settingPages
    })
  }

  return navigationConfig
}
export default createNavigationConfig
