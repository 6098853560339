import React, { Fragment, Suspense } from 'react'
import { renderRoutes, RouteConfigComponentProps, RouteConfig } from 'react-router-config'
import { LinearProgress, makeStyles } from '@material-ui/core'

// import { Topbar } from './components'

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}))

const AuthLayout: React.FC<RouteConfigComponentProps<{ routes: any }>> = ({ route }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {/* <Topbar /> */}
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>{renderRoutes(route?.routes)}</Suspense>
      </main>
    </Fragment>
  )
}

export default AuthLayout
