import React, { PropsWithChildren } from 'react'
import { AppContext } from 'contexts/AppContext'
import { Redirect } from 'react-router-dom'
import Error401 from 'modules/errors/Error401'

const AuthorizedComponent: React.FC<{
  roles?: string[]
  resource?: string
  scope?: string
  scopes?: string[]
  alt?: React.ReactNode
  children: PropsWithChildren<any>
}> = ({ roles, resource, scope, scopes, alt, children }) => {
  const { state, isAuthorized } = React.useContext(AppContext)

  if (!state.session.user) return alt ? alt : null

  let allow = isAuthorized({ roles, resource, scope, scopes })

  if (allow) {
    return children
  }
  return alt ? alt : null
}

export default AuthorizedComponent
