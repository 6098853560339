import { Snackbar } from '@material-ui/core'
import React from 'react'
import FlashContentWrapper, { flashVariantIcon } from './FlashContentWrapper'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

type FlashWrapperProps = {
  open: boolean
  flashMessage: string
  flashVariant: keyof typeof flashVariantIcon
  onClose?: () => void
}

const FlashWrapper: React.FC<FlashWrapperProps> = ({
  open,
  flashMessage,
  flashVariant,
  onClose,
  ...props
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      // open={flashMessage && flashMessage.length > 0 ? true : false}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      // message={flashMessage}
      {...props}
    >
      <MuiAlert
        elevation={8}
        variant="filled"
        onClose={onClose}
        severity={flashVariant}
      >
        {flashMessage}
      </MuiAlert>
    </Snackbar>
  )
  {
    /* <FlashContentWrapper
    onClose={onClose}
    variant={flashVariant}
    message={flashMessage ? flashMessage : ''}
  /> */
  }
}

export default FlashWrapper
