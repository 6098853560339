import React, { lazy, PropsWithChildren } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthLayout from './modules/layouts/AuthLayout'
import ErrorLayout from './modules/layouts/ErrorLayout'
import HomeLayout from './modules/layouts/HomeLayout'
import HomePage from 'modules/home/HomePage'
import {
  RouteConfig,
  RouteConfigComponentProps,
  renderRoutes
} from 'react-router-config'
import { AppContext } from 'contexts/AppContext'
import AuthorizedPage from 'modules/authentication/components/AuthorizedPage'
// import MerchantEditPage from 'modules/merchants/MerchantEditPage'
const MerchantEditPage = lazy(() =>
  import('modules/merchants/MerchantEditPage')
)

const PrivateRoute: React.FC<{ children: PropsWithChildren<any> }> = ({
  children
}) => {
  const { state } = React.useContext(AppContext)

  const isLoggedIn = state.session.user

  return isLoggedIn ? children : <Redirect to="/auth/signin" />
}

const RouteWithSubRoutes: React.FC<RouteConfigComponentProps> = ({ route }) => {
  if (!route) return null

  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        // @ts-ignore
        <route.component {...props} routes={route.routes} />
      )}
    />
  )
}

const AppRoutes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/signin',
        exact: true,
        component: lazy(() => import('modules/authentication/SignInPage'))
      },
      {
        path: '/auth/forget',
        exact: true,
        component: lazy(() => import('modules/authentication/ForgetPage'))
      },
      {
        path: '/auth/new-password/:id',
        component: lazy(() => import('modules/authentication/NewPasswordPage'))
      },

      // {
      //   path: '/auth/register',
      //   exact: true,
      //   component: lazy(() => import('views/Register'))
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('modules/errors/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('modules/errors/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('modules/errors/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: HomeLayout,
    // component: () => (
    //   <PrivateRoute>
    //     <HomeLayout />
    //   </PrivateRoute>
    // ),
    routes: [
      {
        path: '/home',
        exact: true,
        component: () => (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        )
      },
      {
        path: '/profile',
        exact: false,
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/profile/me',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/users/ProfileEditPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/partners',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/partners',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/partners/PartnersPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/partners/new',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/partners/PartnerNewPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/partners/:id',
            exact: true,
            component: () => {
              const Page = lazy(() =>
                import('modules/partners/PartnerEditPage')
              )
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/merchants',
        exact: false,
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/merchants',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/merchants/MerchantsPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/merchants/new',
            exact: true,
            component: () => {
              const Page = lazy(() =>
                import('modules/merchants/MerchantNewPage')
              )
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/merchants/:id',
            exact: false,
            component: () => {
              return (
                <PrivateRoute>
                  <MerchantEditPage />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/users',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/users/:realm',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/users/UsersPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/users/:realm/new',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/users/UserNewPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/users/:realm/:id',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/users/UserEditPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/users/forget',
            exact: true,
            component: lazy(() => import('modules/users/ForgetPage'))
          },
          {
            path: '/users/new-password/:id',
            component: lazy(() => import('modules/users/NewPasswordPage'))
          }
        ]
      },
      {
        path: '/transactions',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/transactions',
            exact: true,
            component: () => {
              const Page = lazy(() =>
                import('modules/transactions/TransactionsPage')
              )
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/roles',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/roles',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/roles/RolesPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/roles/:id',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/roles/RoleResourcesPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/void',
        exact: true,
        component: () => {
          const Page = lazy(() => import('modules/void/SearchPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },
      {
        path: '/settlements',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/settlements',
            exact: true,
            component: () => {
              const Page = lazy(() =>
                import('modules/settlements/SettlementsPage')
              )
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/settlements/:merchantId',
            exact: false,
            component: ({ route }: RouteConfigComponentProps) => (
              <div>{renderRoutes(route && route.routes)}</div>
            ),
            routes: [
              {
                path: '/settlements/:merchantId/:id/submit',
                exact: true,
                component: (props: any) => {
                  const Page = lazy(() =>
                    import('modules/settlements/SettlementSubmitPage')
                  )
                  return (
                    <PrivateRoute>
                      <Page {...props} />
                    </PrivateRoute>
                  )
                }
              },
              // {
              //   path: '/settlements/:merchantId/create',
              //   exact: true,
              //   component: (props: any) => {
              //     const Page = lazy(
              //       () => import('modules/settlements/SettlementCreatePage')
              //     )
              //     return (
              //       <PrivateRoute>
              //         <Page {...props} />
              //       </PrivateRoute>
              //     )
              //   }
              // },
              {
                path: '/settlements/:merchantId/:id',
                exact: true,
                component: () => {
                  const Page = lazy(() =>
                    import('modules/settlements/SettlementEditPage')
                  )
                  return (
                    <PrivateRoute>
                      <Page />
                    </PrivateRoute>
                  )
                }
              },
              {
                path: '/settlements/:merchantId',
                exact: true,
                component: () => {
                  const Page = lazy(() =>
                    import('modules/settlements/SettlementListPage')
                  )
                  return (
                    <PrivateRoute>
                      <Page />
                    </PrivateRoute>
                  )
                }
              }
            ]
          }
        ]
      },
      {
        path: '/refunds',
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/refunds',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/refunds/RefundsPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/refunds/new',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/refunds/RefundNewPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/refunds/:id',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/refunds/RefundEditPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/providers',
        exact: false,
        component: () => {
          const Page = lazy(() => import('modules/provider/ProvidersPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },
      {
        path: '/settings/:index?',
        exact: false,
        component: () => {
          const Page = lazy(() => import('modules/settings/SettingsPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },
      {
        path: '/overview',
        exact: true,
        component: () => (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        )
      },
      {
        path: '/promos',
        exact: false,
        component: () => {
          const Page = lazy(() => import('modules/promos/PromosPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },
      {
        path: '/audits',
        exact: false,
        component: () => {
          const Page = lazy(() => import('modules/audits/AuditsPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },
      {
        path: '/currencies',
        exact: false,
        component: ({ route }: RouteConfigComponentProps) => (
          <div>{renderRoutes(route && route.routes)}</div>
        ),
        routes: [
          {
            path: '/currencies',
            exact: true,
            component: () => {
              const Page = lazy(() =>
                import('modules/currencies/CurrenciesPage')
              )
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          },
          {
            path: '/currencies/:id',
            exact: true,
            component: () => {
              const Page = lazy(() => import('modules/currencies/RatesPage'))
              return (
                <PrivateRoute>
                  <Page />
                </PrivateRoute>
              )
            }
          }
        ]
      },
      {
        path: '/risks',
        exact: false,
        component: () => {
          const Page = lazy(() => import('modules/risk/RiskListPage'))
          return (
            <PrivateRoute>
              <Page />
            </PrivateRoute>
          )
        }
      },

      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
]
export default AppRoutes
