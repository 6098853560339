import React from 'react'
import logo from './logo.svg'
import './App.css'
import LoadingScreen from 'components/LoadingScreen'
import { AppProvider, AppContext } from 'contexts/AppContext'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { orange } from '@material-ui/core/colors'

import theme from './theme'
import './assets/scss/index.scss'
import HomePage from 'modules/home/HomePage'
import { renderRoutes } from 'react-router-config'
import AppRoutes from 'AppRoutes'
import dayjs from 'dayjs'
// import './mixins/chartjs'
// import './mixins/moment'
// import './mixins/validate'
// import './mixins/prismjs'
// import './mock'

// const CheckoutPage = React.lazy(() => {
//   return new Promise<any>((resolve) => {
//     setTimeout(() => resolve(import('modules/CheckoutPage')), 2000)
//   })
//   // return Promise.all<any>([import('modules/CheckoutPage'), new Promise((resolve) => setTimeout(resolve, 1000))]).then(([moduleExports]) => moduleExports)
// })
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

function App() {
  // return <LoadingScreen />
  // const [accessToken, setAccessToken] = React.useState<string | null>(storageManager.load('accessToken') || null)
  // const { state, dispatch } = React.useContext(AppContext)

  // React.useEffect(() => {
  //   dispatch({
  //     type: 'GET_USERS_ME',
  //     payload: {}
  //   })
  // }, [accessToken])

  return (
    <AppProvider>
      <MuiThemeProvider theme={theme}>
        <React.Suspense fallback={<LoadingScreen />}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>{renderRoutes(AppRoutes)}</Switch>
          </BrowserRouter>
        </React.Suspense>
      </MuiThemeProvider>
    </AppProvider>
  )
}

// const theme = createMuiTheme({
//   palette: {
//     primary: orange
//   }
// })

export default App
