import axios from 'axios'

const Api = axios.create({
  baseURL: process && process.env && process.env.REACT_APP_API_URL,
  timeout: 60000
})

Api.defaults.baseURL = process && process.env && process.env.REACT_APP_API_URL

export default Api
