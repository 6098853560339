import React, { Fragment, useEffect, useContext, useState } from 'react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  Theme
} from '@material-ui/core'
import { Hidden } from '@material-ui/core'
import Navigation from 'components/Navigation'
import createNavigationConfig from './navigationConfig'
import { AppContext } from 'contexts/AppContext'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  profileLink: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    fontSize: '0.7em'
  }
}))

type NavBarProps = {
  className?: string
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const NavBar: React.SFC<NavBarProps> = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props
  const classes = useStyles()
  const router = useRouteMatch()

  const context = useContext(AppContext)
  const { state, dispatch } = context
  const [navigationConfig, setNavigationConfig] = useState<any[]>()

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }

    setNavigationConfig(
      createNavigationConfig(context, state.session.user || undefined)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.session.user, router.path])

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={
            state.session.user && state.session.user.photoUrl
              ? state.session.user.photoUrl
              : undefined
          }
          to={`/profile/me`}
        />
        <Typography className={classes.name} variant="h4">
          {state.session.user && state.session.user.name}
        </Typography>
        <Typography variant="body2">
          {state.session.user && state.session.user.roles.join('/')}
        </Typography>
        <RouterLink className={classes.profileLink} to="/profile/me">
          Edit Profile
        </RouterLink>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig &&
          navigationConfig.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
      </nav>
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  )
}

export default NavBar
