/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  colors,
  Theme
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import { AppContext } from 'contexts/AppContext'
// import { PricingModal, NotificationsPopover } from 'components'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: '#ffffff', // theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}))

type TopBarProps = {
  className?: string
  showNavBar: boolean
  onOpenNavBarMobile?: (...args: any[]) => any
}

const TopBar: React.SFC<TopBarProps> = (props) => {
  const { showNavBar, onOpenNavBarMobile, className, ...rest } = props
  const classes = useStyles()
  const route = useRouteMatch()
  const searchRef = useRef(null)
  const notificationsRef = useRef(null)
  const [pricingModalOpen, setPricingModalOpen] = useState(false)
  const [openSearchPopover, setOpenSearchPopover] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [notifications, setNotifications] = useState([])
  const [openNotifications, setOpenNotifications] = useState(false)

  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    let mounted = true
    // const fetchNotifications = () => {
    //   axios.get('/api/account/notifications').then((response: any) => {
    //     if (mounted) {
    //       setNotifications(response.data.notifications)
    //     }
    //   })
    // }
    // fetchNotifications()
    return () => {
      mounted = false
    }
  }, [])
  const handleLogout = () => {
    dispatch({ type: 'SIGNOUT', payload: null })
    // sessionContext.clearSessions()
    // history.push('/')
    // dispatch(logout());
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
      elevation={0}
    >
      <Toolbar>
        {showNavBar ? (
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        ) : null}
        <RouterLink to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/bank-mega-logo.png`}
            alt="Mega Pay"
            sizes="small"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Toolbar>
      {/* <PricingModal onClose={handlePricingClose} open={pricingModalOpen} /> */}
      {/* <NotificationsPopover anchorEl={notificationsRef.current} notifications={notifications} onClose={handleNotificationsClose} open={openNotifications} /> */}
    </AppBar>
  )
}
export default TopBar
